import React from "react";

const CaretLeft = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 5.4375L9.0625 14.5L18.125 23.5625"
        stroke="#413A44"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CaretLeft };
