const DeliveryDetailRow = ({ delivery }) => {
  const { quantity } = delivery;
  const { total } = delivery.amounts;
  const { coordinator, artisan } = delivery.from_artisan_production_order_item;
  const { code, short_description } =
    delivery.from_artisan_production_order_item.product;
    const batch = delivery?.price_list_batch ? delivery?.price_list_batch?.batch?.name : 'A'
  return (
    <tbody>
      <tr className="entregas-data-row">
        <td>{coordinator}</td>
        <td>{artisan}</td>
        <td>{code}</td>
        <td>{short_description}</td>
        <td>{quantity}</td>
        <td>{batch}</td>
        <td>{total}</td>
      </tr>
    </tbody>
  );
};

export { DeliveryDetailRow };
