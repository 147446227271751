import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const errors = [401, 403, 404, 500];

const useGetData = (endpoint, query, isEnabled = true) => {

  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: [query],
      queryFn: () => client(`admin/${endpoint}/`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isEnabled,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useSubmitData = (endpoint, query) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`admin/${endpoint}/`, {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.refetchQueries([query]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

const useGetDataById = (endpoint, query, id) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: [query, id],
      queryFn: () => client(`admin/${endpoint}/${id}/`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      //disabled if id is undefined
      enabled: !!id,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? {},
    isLoading,
    isError,
    isSuccess,
  };
};

const useDeleteData = (endpoint, query) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) =>
      client(`admin/${endpoint}/${id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.refetchQueries([query]),
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

const useGetStockItemsByQuery = (endpoint, queryKey, query, enabled) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: [queryKey, query],
      queryFn: () => client(`admin/${endpoint}?${query}`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useGetStockQuantitiesByProductId = (
  endpoint,
  queryKey,
  query,
  isSellOrder
) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: [queryKey],
      queryFn: () =>
        client(`admin/${endpoint}/query?${query}`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: !!isSellOrder,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};

export {
  useGetData,
  useSubmitData,
  useGetDataById,
  useGetStockItemsByQuery,
  useGetStockQuantitiesByProductId,
  useDeleteData,
};
