import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const errors = [401, 403, 404, 500];

const useGetArtisansData = (filters = {}) => {
  const { pageNumber, name, ordering, association, community, enabled = true } = filters;
  const { data, isLoading, isError, isSuccess, isPreviousData } = useQuery(
    {
      queryKey: ["artisans", filters],
      queryFn: () =>
        client(`admin/artisans/?page=${pageNumber}`
        , {
          params: {
            name,
            ordering,
            association,
            community,
          },
        }
        )
        .then((data) => data),
      keepPreviousData: true,
      enabled: enabled,
      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useGetArtisansByAssociationQuery = (associationId) => {
  const { data, isLoading } = useQuery(
    {
      queryKey: ["artisans-by-association", associationId],

      queryFn: () =>
        client(`admin/artisans/simplified?association=${associationId}`).then(
          (data) => data
        ),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      enabled: !!associationId,
    },

    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
  };
};

const useAddArtisan = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data) =>
      client("admin/artisans/", {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.refetchQueries(["artisans"]),
    }
  );
};

const useEditArtisan = (id) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data) =>
      client(`admin/artisans/${id}/`, {
        method: "PUT",
        data,
      }),

    {
      onSuccess: () => queryClient.invalidateQueries(["artisan", id]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

const useAddImageToArtisan = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`admin/artisans/${data.id}/set_picture/`, {
        method: "POST",
        data: data.full_file,
        headers: {
          "Content-Type": data.full_file.type,
          "Content-Disposition": `attachment; filename=${data.full_file.name}`,
          filename: data.full_file.name,
        },
      }),
    {
      onSuccess: (data) => queryClient.refetchQueries(["artisan", data.user]),
    }
  );
};

const useExportArtisanList = () => {
  return useMutation(() =>
    client(`admin/artisans/export_excel/`, {
      method: "GET",
      responseType: 'blob',
    }).then((response) => {

      const url = window.URL.createObjectURL(response)

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'artisan_list.xlsx');
      
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  );
};


export {
  useAddArtisan,
  useEditArtisan,
  useGetArtisansByAssociationQuery,
  useAddImageToArtisan,
  useGetArtisansData,
  useExportArtisanList,
};
